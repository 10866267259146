<template>
	<b-row>
		<b-col cols="12" xl="12">
			<firin-kontrol-filtre
			v-if="filtreYetki"
			class="ml-1ch mb-3vh mb-top-2vh"
			ref="filtre" 
		/>
		</b-col>
		<b-col :class="dateisOpened ? '' : 'table-responsive'" cols="12" xl="12">
			<table class="table table-condensed table-bordered table-hover table-light ">
				<thead class="thead-dark">
					<tr>
						<th><label for="tarih">Tarih</label> 
							<datepicker
							v-show="false"
							@click.native="dateisOpened = true"
							@focusout.native="dateisOpened = false"
							wrapper-class="form-control" id="tarih" :language="tr" input-class="borderless" v-model="tarih"></datepicker></th>
						
						<th>Saat</th>
						<th>Ürün Adı</th>
						<th>Pişirme Süresi</th>
						<th>Pişirme Sıcaklığı</th>
						<th>Egzost %</th>
						<th>Metering Belt</th>
						<th>Nem</th>
						<th>Açıklama</th>
						<th><button type="button" class="btn btn-lg btn-block btn-success" @click="modal.show = true">Oluştur</button></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="satir in satirlar" :key="satir.ID">
						<td>{{ tarihFormatla(satir.Tarih, 'DD.MM.YYYY') }}</td>
						<td>{{ saatFormatla(satir.Saat) }}</td>
						<td>{{ satir.UrunAdi }}</td>
						<td>{{ satir.Sure }}</td>
						<td>{{ satir.Sicaklik }}</td>
						<td>{{ satir.Egzost }}</td>
						<td>{{ satir.MeteringBelt }}</td>
						<td>{{ satir.Nem }}</td>
						<td>{{ satir.Yorumlar }}</td>
						<td>
							<button v-if="DuzenlemeYapabilirmi" type="button" class="btn btn-lg btn-block btn-css3" @click="editForm(satir)">Düzenle</button>
						</td>
					</tr>
				</tbody>
			</table>
		</b-col>
		<b-col cols="6" xl="6">
			<vue-touch-keyboard class="virtual-keyboard" v-if="keyboard.visible" :layout="keyboard.layout" :cancel="hide" :accept="accept" :input="keyboard.input" />
		</b-col>
		<b-modal @hidden="icerikTemizle" size="lg" title="Fırın Kontrol Formu Ekleme" ok-only ok-title="Fırın Formunu Kaydet" text="Fırın Formunu Kaydet" :class="modal.className" v-model="modal.show" @ok="$event.preventDefault(); firinFormuKaydet()" :ok-variant="modal.variant" :no-enforce-focus='true'>
			<b-row>
				<b-col cols="6" xl="6">
					<label for="saat">Saat</label> <em class="pull-right clr-red" v-if="$v.saat.$error">Saat Belirtmediniz</em>
					<the-mask @focus.native="show" data-layout="numeric" class="form-control" id="saat" v-model="saat" mask="##:##" value="" type="text" :masked="false" placeholder="__:__"></the-mask>
				</b-col>
				<b-col cols="6" xl="6">
					<label for="urun">Ürün Adı</label> <em class="pull-right clr-red" v-if="$v.seciliUrun.$error">Ürün Seçmediniz</em>
					<select class="form-control" id="urun" v-model="seciliUrun">
						<option v-for="urun in urunler" :key="urun.id" :value="urun.id">{{ urun.adi }}</option>
					</select>
				</b-col>
				<b-col cols="6" xl="6">
					<label for="sure">Pişirme Süresi</label> <em class="pull-right clr-red" v-if="$v.sure.$error">Süre Belirtmediniz</em>
					<b-input @focus.native="show" data-layout="numeric" type="number" v-model="sure" id="sure"/>
				</b-col>
				<b-col cols="6" xl="6">
					<label for="sicaklik">Pişirme Sıcaklığı</label> <em class="pull-right clr-red" v-if="$v.sicaklik.$error">Sıcaklık Belirtmediniz</em>
					<b-input @focus.native="show" data-layout="numeric" type="number" v-model="sicaklik" id="sicaklik"/>
				</b-col>
				<b-col cols="6" xl="6">
					<label for="egzost">Egzost %</label> <em class="pull-right clr-red" v-if="$v.egzost.$error">Egzost Belirtmediniz</em>
					<b-input @focus.native="show" data-layout="numeric" type="number" v-model="egzost" id="egzost"/>
				</b-col>
				<b-col cols="6" xl="6">
					<label for="metering_belt">Metering Belt</label> <em class="pull-right clr-red" v-if="$v.metering_belt.$error">Metering Belt Belirtmediniz</em>
					<b-input @focus.native="show" data-layout="numeric" type="number" v-model="metering_belt" id="metering_belt"/>
				</b-col>
				<b-col cols="6" xl="6">
					<label for="nem">Nem</label> <em class="pull-right clr-red" v-if="$v.nem.$error">Nem Belirtmediniz</em>
					<b-input @focus.native="show" data-layout="numeric" type="number" v-model="nem" id="nem"/>
				</b-col>
				<b-col cols="6" xl="6">
					<label for="yorumlar">Açıklama</label> 
					<b-input @focus.native="show" data-layout="compact" type="text" v-model="yorumlar" id="yorumlar"/>
				</b-col>
			</b-row>

			<b-row v-if="modal.error">
				<b-col cols="6" xl="6">
					<hr v-if="modal.error">
					<b-alert variant="danger" :show="modal.error">{{ modal.message }}</b-alert>
				</b-col>
			</b-row>
		</b-modal>
		<sweet-modal ref="saatModal" modal-theme="dark">
			<b-row>
				<b-col cols="12" xl="12">
					<h3>Saat Seçimi</h3>
				</b-col>
				<b-col cols="6" xl="6">
					<label for="saat_bolumu">Saat</label>
					<input ref="saat_bolumu" class="form-control" type="number" v-model="saat_bolumu" autofocus/>
				</b-col>
				<b-col cols="6" xl="6">
					<label for="dakika_bolumu">Dakika</label>
					<input class="form-control" type="number" v-model="dakika_bolumu"/>
				</b-col>
			</b-row>
			<button class="btn btn-md btn-warning btn-block mb-top-2vh" @click="saatKaydet">Tamam</button>
		</sweet-modal>
	</b-row>
</template>

<script>

import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import { tr } from 'vuejs-datepicker/dist/locale'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { headers, User } from '@/shared/http';
import { SweetModal } from 'sweet-modal-vue'
import { TheMask } from 'vue-the-mask'
import { mapGetters, mapActions } from 'vuex'
import { required, numeric, minValue } from 'vuelidate/lib/validators'
import layoutTr from '@/shared/layout-tr'

export default {
	name: 'PendingApprovals',
	components: {
		Datepicker,
		flatPickr,
		SweetModal,
		TheMask,
	},
	data() {
		return {
			keyboard: {
				visible: false,
				layout: layoutTr.numeric,
				input: false,
				options: {
					useKbEvents: false
				},
			},
			tip: 17,
			BolumID: 1,
			formID: 0,
			DuzenlemeYapabilirmi: false,
			tr: tr,
			dateisOpened: false,
			modal: {
				show: false,
				variant: 'primary',
				className: 'modal-success',
				error: false,
				message: null,
			},
			saat_bolumu: null,
			dakika_bolumu: null,
			tarih: moment().format('YYYY-MM-DD'),
			saat: null,
			urunler: [],
			seciliUrun: 0,
			sure: null,
			sicaklik: null,
			egzost: null,
			metering_belt: null,
			nem: null,
			yorumlar: null,
			filtreYetki: false
			//satirlar: []
		}
	},
	validations: {
		saat: {
			required
		},
		seciliUrun: {
			required,
			numeric,
			minValue: minValue(1)
		},
		sure: {
			required,
			numeric,
			minValue: minValue(0)
		},
		sicaklik: {
			required,
			numeric,
			minValue: minValue(0)
		},
		egzost: {
			required,
			numeric,
			minValue: minValue(0)
		},
		metering_belt: {
			required,
			numeric,
			minValue: minValue(0)
		},
		nem: {
			required,
			numeric,
			minValue: minValue(0)
		},
	},
	computed: {
		...mapGetters([
			'satirlar'
		])
	},
	mounted() {
		this.verileriGetir()
		this.urunlerGetir()
		this.bolumKontrolu()
	},
	methods: {
		...mapActions([
			'fillSatirlar'
		]),
		accept(text) {
			this.hide();
		},
		show(e) {
			this.keyboard.input = e.target;
			this.keyboard.layout = layoutTr[e.target.dataset.layout];

			if (!this.keyboard.visible)
				this.keyboard.visible = true
		},
		hide() {
			this.keyboard.visible = false;
		},
		firinFormuKaydet() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				fetch(`${this.$api}/api/firinkontrol`, {
					method: 'POST',
					headers: headers,
					body: JSON.stringify({
						TipID: this.tip,
						Tarih: moment().format('YYYY-MM-DD'),
						Saat: this.saat,
						UrunID: this.seciliUrun,
						Sure: this.sure,
						Sicaklik: this.sicaklik,
						Egzost: this.egzost,
						MeteringBelt: this.metering_belt,
						Nem: this.nem,
						Yorumlar: this.yorumlar,
						ID: this.formID,
						...User
					})
				}).then(resp => resp.json())
				.then(obj => {
					
					if (obj.Success) {
						this.modal.show = false;
						this.modal.error = false;
						this.saat = null
						this.seciliUrun = 0
						this.sure = null
						this.sicaklik = null
						this.egzost = null
						this.metering_belt = null
						this.nem = null
						this.yorumlar = null
						this.formID = 0
						this.verileriGetir()
						document.querySelector("#filtrele").click()
					} else {
						this.modal.show = true;
						this.modal.error = true;
						this.modal.message = obj.Message;
					}
					
				})
			}
		},
		verileriGetir() {
			
			const tarih = moment().format('YYYY-MM-DD');

			fetch(`${this.$api}/api/firinkontrol/${this.tip}/${tarih}`, {
				method: 'GET',
				headers: headers,
			}).then(resp => resp.json())
			.then(obj => {

				this.fillSatirlar(obj)
				
			})
		},
		urunlerGetir() {
			fetch(`${this.$api}/api/generic/urunler`, {
				method: 'GET',
				headers: headers,
			}).then(resp => resp.json())
			.then(obj => {
				this.urunler = [
					{
						id: 0,
						adi: 'Seçiniz'
					}
				]
				if (obj.length > 0) {
					obj.forEach((u) => {
						this.urunler.push({
							id: u.ID,
							adi: u.UrunAdi,
						})
					})
				}
				
			})
		},
		tarihFormatla(tarih, format) {
			return moment(tarih).format(format)
		},
		saatModal(model) {
			this.seciliSaat = model
			this.$refs.saatModal.open()
			
			this.$nextTick(() => this.$refs.saat_bolumu.focus())
			
		},
		saatKaydet() {
			
			if(!this.saat_bolumu) this.saat_bolumu = moment().format('HH')
			if(!this.dakika_bolumu) this.dakika_bolumu = moment().format('mm')
			
			this[this.seciliSaat] = this.saat_bolumu + ':' + this.dakika_bolumu

			this.$refs.saatModal.close()

			this.saat_bolumu = null
			this.dakika_bolumu = null
		},
		bolumKontrolu() {
			const { PersonelID } = User
			fetch(`${this.$api}/api/Generic/PersonelYetkileri/${PersonelID}`, {
				method: 'GET',
				headers: headers,
			}).then(resp => resp.json())
			.then(obj => {
				if (obj.length > 0) {
					this.DuzenlemeYapabilirmi = obj[0].DuzenlemeYapabilir
					this.filtreYetki = obj[0].FiltrelemeYapabilir
				}
			})
		},
		editForm(data) {
			this.formID = data.ID
			this.saat = this.saatFormatla(data.Saat)
			this.seciliUrun = data.UrunID
			this.sure = data.Sure
			this.sicaklik = data.Sicaklik
			this.egzost = data.Egzost
			this.metering_belt = data.MeteringBelt
			this.nem = data.Nem
			this.yorumlar = data.Yorumlar
			this.modal.show = true
		},
		saatFormatla(saat) {
			if(!Boolean(saat)) {
				return ''
			}

			saat = saat.replace(/:/ig, '')

			let saatArray = saat.split('')
			let sol = saatArray.splice(0, 2).join('')
			let sag = saatArray.splice(0, 2).join('')

			return `${sol}:${sag}`
		},
		icerikTemizle() {
			this.keyboard.visible = false
			this.saat = null
			this.seciliUrun = 0
			this.sure = null
			this.sicaklik = null
			this.egzost = null
			this.metering_belt = null
			this.nem = null
			this.yorumlar = null
			this.formID = 0
			this.$v.$reset()
		}
	}
}
</script>

<style >
.borderless {
	border: none !important;
}

.form-control:disabled, .form-control[readonly] {
	background-color: #ffffff;
}
</style>

